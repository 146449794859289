/* global styling */
.issue-header {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
}

.issue-theme {
  font-family: Times New Roman;
  font-style: italic;
  font-weight: normal;
}

.view-button a{
  display: block;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #FC08D5;
  text-decoration: none;
  background: none;
  padding-left: 6px;
  padding-top: 4px;
  padding-right: 6px;
  padding-bottom: 3px;
}

.view-button {
  margin-top: 12px;
  border: 2px solid #FC08D5;
  border-radius: 7px;
  text-align: center;
  width: 220px;
}

.view-button a:hover{
  background: #FC08D5;
  color: #FFFFFF;
}

/* for smaller screens */
@media only screen and (max-width: 700px) {
  .cover {
    width: 70%;
  }

  .view-button {
    margin-top: 12px;
    border: 2px solid #FC08D5;
    border-radius: 7px;
    text-align: center;
    width: 160px;
  }

  .issue9-container {
    padding: 25px;
    position: absolute;
    top: 89.75px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .issue-header {
    font-size: 14px;
  }

  .issue-theme {
    font-size: 38px;
    padding-bottom: 27px;
  }

  .issue9-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

/* this is for large screen (laptop) */
@media screen and (min-width: 701px) {
  .view-button {
    width: 160px;
    margin-bottom: 20px;
  }

  .cover {
    width: 330px;
    margin-top: 146px;
    margin-bottom: 5px;
  }

  .issue9-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .issue-header {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;

    position: absolute;
    left: 79px;
    top: 126px;
  }

  .issue-theme {
    font-size: 52px;
    line-height: 60px;

    position: absolute;
    left: 79px;
    top: 146px;
  }

  .issue9-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
