.prev-next-container {
  height: 100%;
  position: fixed;
  width: 21%;
  right: 0px;
  top: 120px;
}

.prev-next-container a {
  color: black;
}

.pn-content {
 margin-top: 40%;
}

.pn-text {
  font-family: Times;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  width: 150px;
  margin-left: 16%;
  padding-top: 10px;
  padding-bottom: 16px;
}

.arrow-image {
  width: 150px;
  margin-left: 16%;
}

/** mobile styles **/
@media only screen and (max-width: 600px) {
  .prev-next-container {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    padding: 5%;
    justify-content: space-between;
  }

  .pn-content {
   margin-top: 0;
   width: 100%;
   display: flex;
  }

  .pn-text {
    font-size: 12px;
    line-height: 12px;
    padding-bottom: 16px;
    margin-left: 0;
  }

  .arrow-image {
    width: 80px;
    margin-left: 0;
  }

  .right {
    text-align: right;
  }
}
