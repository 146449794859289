.image {
    margin: auto auto 15px 15px;
    display: inline-block;
    height: 400px;
}

.image-container {
    padding-top: 12%;
    display: inline-block;
    white-space: nowrap;
    overflow: visible;
}
