.post-grid {
  width: 100%;
  padding-top: 1.2%;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body a {
  text-decoration: none;
}



/** styles for large screens (tablets, laptops)**/
.post-thumbnail {
  padding: 1.5%;
  width: 33.33%;
}

.post-thumbnail-title {
  padding-top: 30px;
  font-family: Times;
  font-style: italic;
  font-weight: 100;
  font-size: 24px;
  line-height: 28px;
  color: black;
  text-decoration: none;
}

.post-thumbnail-title:hover {
  text-decoration: underline;
}

.post-thumbnail-image {
  object-fit: cover;
  width: 100%;
  height: 292px;
}

.post-thumbnail-image:hover {
  opacity: 50%;
}

.post-thumbnail-blurb {
  width: 80%;
  font-family: "Times New Roman";
  font-size: 16px;
  line-height: 18px;
  font-weight: 100;
  color: black;
  padding-top: 14px;
}

.post-thumbnail-credits {
  color: #7C7C7C;
  font-size: 12px;
  padding-top: 14px;
  font-family: Arial;
}

/** styles for smol screens **/
@media screen and (max-width: 768px) {
  .post-thumbnail {
    width: 48%;
  }
}

/** styles for smol screens **/
@media screen and (max-width: 500px) {
  .post-thumbnail {
    width: 96%;
    padding-bottom: 20px;
  }
}
