html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	background-color: #FFF4F4;
}

.home-intro {
	font-family: "Times New Roman", Times, serif;
	font-style: italic;
	line-height: normal;
}

.home-apply {
	display: grid;
	justify-content: center;
	align-items: center;
	margin-left: auto;
  margin-right: auto;
}

.home-vector1 {
	width: 210px;
	opacity: 0.95;
	margin-left: 0px;
}

.issue-button a{
	display: block;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #FC08D5;
  text-decoration: none;
  background: none;
	padding-left: 8px;
	padding-top: 6px;
	padding-right: 8px;
	padding-bottom: 5px;
}

.issue-button {
  margin-top: 22px;
  border: 2px solid #FC08D5;
  border-radius: 7px;
  text-align: center;
	width: 180px;
}

.issue-button a:hover{
  background: #FC08D5;
  color: #FFFFFF;
}

/* phone screen size */
@media screen and (max-width: 599px ) {
	.home-intro {
		padding-top: 35%;
		width: 80%;
		margin: 0 auto;
		font-size: 31px;
		line-height: 36px;
		text-align: center;
		margin-bottom: 14px;
	}
	.issue-button {
	  margin-top: 15px;
	  border: 2px solid #FC08D5;
	  border-radius: 7px;
	  text-align: center;
		width: 180px;
		margin: 0 auto;
	}
	.footer-container {
	    width: 100%;
	    position: absolute;
	    text-align: center;
	    height: 40px;
	    bottom: 3px;
	}
}

/* large screen size */
@media screen and (min-width: 600px) {
	.home-intro {
		width: 555px;
		font-size: 48px;
		line-height: 55px;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -150px 0 0 -277px;
	}

	.issue-button {
		position: absolute;
		top: 100%;
		left: 50%;
		margin: -130px 0 0 -90px;
	}
}

.footer-container {
    width: 100%;
    position: absolute ;
    text-align: center;
    height: 40px;
    bottom: 5px;
}

.icon {
    padding: 5px;
    width: 40px;
}
