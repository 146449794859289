html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

@media only screen and (max-width: 600px) {
	body .container {
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 75px;
	}
	body form {
		grid-template-columns: 1fr;
	}
	body .submissions-header p {
		width: 100%;
	}
	body .submissions-header {
		padding-top: 0px;
		font-size: 24px;
	}
}

form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	justify-content: center;
	align-items: center;
}


.input {
	background-color: white;
	border-radius: 6px;
	border: none;
	padding: 10px;
}

.submit {
	background-color: #FC08D5; /* Pink */
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	font-size: 16px;
	height: 40px;
	width: 90px;
	border-radius: 6px;
}

.disclaimer {
	font-family: "Times New Roman", Times, serif;
	font-style: italic;
	font-size: .75em;
}

.container {
	padding-top: 120px;
	margin: auto;
	width: 50%;
}

.form-container {
}

.submissions-header {
	font-family: "Arial", sans-serif;
	text-align: center;
	font-size: 38px;
	font-weight: bold;
	background: transparent;
	padding-top: 30px;
}

.submissions-header p {
	font-family: "Times New Roman", Times, serif;
	font-size: 14px;
	text-align: left;
	font-weight: normal;
	margin-bottom: 20px;
}
