/* 48em = 768px */

body .header {
  	width: 100%;
    padding-top: 0px;
    padding-left: 0px;
}

.header ul {
  list-style: none;
  overflow: hidden;
}

.header li a {
  font-family: "Arial", sans-serif;
  font-weight: bolder;
  font-size: 21px;
  display: block;
  border-bottom: 5px solid #FFF4F4;
  float: right;
  color: #000;
}

.header li a:hover {
  border-bottom: 5px solid black;
}

.header .logo {
  float: left;
  width: 118px;
  padding-top: 29px;
  padding-left: 26px;
}

@media screen and (max-width: 760px) {
  .header .link-padding {
    padding: 20px;
    width: 100%;
  }
}

/* EVERYTHING BELOW THIS POINT IS FOR THE RESPONSIVE HAMBURGER MENU */

/* menu */


.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  float: right;
  padding-top: 34px;
  padding-right: 34px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

@media screen and (min-width: 760px) {
  .header ul {
    right: 20px;
    padding-top: 4px;
    background-color: none;
  }

  .header li {
    float: left;
  }
  .header .logo {
    width: 250px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }

  .header .link-padding {
    float: right;
    padding: 20px;
    padding-left: 26px;
  }
}
