body {
	background-color: #FFF4F4;
	margin: 0px;
}

/* 48em = 768px */

body .header {
  	width: 100%;
    padding-top: 0px;
    padding-left: 0px;
}

.header ul {
  list-style: none;
  overflow: hidden;
}

.header li a {
  font-family: "Arial", sans-serif;
  font-weight: bolder;
  font-size: 21px;
  display: block;
  border-bottom: 5px solid #FFF4F4;
  float: right;
  color: #000;
}

.header li a:hover {
  border-bottom: 5px solid black;
}

.header .logo {
  float: left;
  width: 118px;
  padding-top: 29px;
  padding-left: 26px;
}

@media screen and (max-width: 760px) {
  .header .link-padding {
    padding: 20px;
    width: 100%;
  }
}

/* EVERYTHING BELOW THIS POINT IS FOR THE RESPONSIVE HAMBURGER MENU */

/* menu */


.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  float: right;
  padding-top: 34px;
  padding-right: 34px;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}

.header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 240px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

@media screen and (min-width: 760px) {
  .header ul {
    right: 20px;
    padding-top: 4px;
    background-color: none;
  }

  .header li {
    float: left;
  }
  .header .logo {
    width: 250px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }

  .header .link-padding {
    float: right;
    padding: 20px;
    padding-left: 26px;
  }
}

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	background-color: #FFF4F4;
}

.home-intro {
	font-family: "Times New Roman", Times, serif;
	font-style: italic;
	line-height: normal;
}

.home-apply {
	display: grid;
	justify-content: center;
	align-items: center;
	margin-left: auto;
  margin-right: auto;
}

.home-vector1 {
	width: 210px;
	opacity: 0.95;
	margin-left: 0px;
}

.issue-button a{
	display: block;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: #FC08D5;
  text-decoration: none;
  background: none;
	padding-left: 8px;
	padding-top: 6px;
	padding-right: 8px;
	padding-bottom: 5px;
}

.issue-button {
  margin-top: 22px;
  border: 2px solid #FC08D5;
  border-radius: 7px;
  text-align: center;
	width: 180px;
}

.issue-button a:hover{
  background: #FC08D5;
  color: #FFFFFF;
}

/* phone screen size */
@media screen and (max-width: 599px ) {
	.home-intro {
		padding-top: 35%;
		width: 80%;
		margin: 0 auto;
		font-size: 31px;
		line-height: 36px;
		text-align: center;
		margin-bottom: 14px;
	}
	.issue-button {
	  margin-top: 15px;
	  border: 2px solid #FC08D5;
	  border-radius: 7px;
	  text-align: center;
		width: 180px;
		margin: 0 auto;
	}
	.footer-container {
	    width: 100%;
	    position: absolute;
	    text-align: center;
	    height: 40px;
	    bottom: 3px;
	}
}

/* large screen size */
@media screen and (min-width: 600px) {
	.home-intro {
		width: 555px;
		font-size: 48px;
		line-height: 55px;
		text-align: center;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -150px 0 0 -277px;
	}

	.issue-button {
		position: absolute;
		top: 100%;
		left: 50%;
		margin: -130px 0 0 -90px;
	}
}

.footer-container {
    width: 100%;
    position: absolute ;
    text-align: center;
    height: 40px;
    bottom: 5px;
}

.icon {
    padding: 5px;
    width: 40px;
}

html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

@media only screen and (max-width: 600px) {
	body .container {
		width: 100%;
		padding-left: 10px;
		padding-right: 10px;
		padding-top: 75px;
	}
	body form {
		grid-template-columns: 1fr;
	}
	body .submissions-header p {
		width: 100%;
	}
	body .submissions-header {
		padding-top: 0px;
		font-size: 24px;
	}
}

form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 10px;
	grid-row-gap: 10px;
	justify-content: center;
	align-items: center;
}


.input {
	background-color: white;
	border-radius: 6px;
	border: none;
	padding: 10px;
}

.submit {
	background-color: #FC08D5; /* Pink */
	border: none;
	color: white;
	text-align: center;
	text-decoration: none;
	font-size: 16px;
	height: 40px;
	width: 90px;
	border-radius: 6px;
}

.disclaimer {
	font-family: "Times New Roman", Times, serif;
	font-style: italic;
	font-size: .75em;
}

.container {
	padding-top: 120px;
	margin: auto;
	width: 50%;
}

.form-container {
}

.submissions-header {
	font-family: "Arial", sans-serif;
	text-align: center;
	font-size: 38px;
	font-weight: bold;
	background: transparent;
	padding-top: 30px;
}

.submissions-header p {
	font-family: "Times New Roman", Times, serif;
	font-size: 14px;
	text-align: left;
	font-weight: normal;
	margin-bottom: 20px;
}

.header{
    padding-top: 80px;
    padding-left: 10px;
}

.comments {
    width: 40%;
}
:root {
    --yellow: #ffc600;
    --black: #272727;
}



/* for smaller screens */
@media only screen and (max-width: 600px) {
  .mag-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      overflow-y: auto;
  }
  .link-container {
      width: 50%;
      height: 374px;
      text-align: center;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      padding-top: 173px;
  }
  .link-container a {
      text-align: center;
      color: black;
      text-decoration: none;
      font-size: 27px;
  }
}

/* for larger screens */
@media only screen and (min-width: 601px) {
  .mag-container {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: 100%;
  }

  .link-container {
      width: 397px;
      height: 100%;
      text-align: center;
  }

  .link-container a {
      color: black;
  }

  .mag {
      position: absolute;
      top: 376px;
      height: 23px;
      text-decoration: none;
      font-style: normal;
      font-size: 57px;
  }
}

.post-grid {
  width: 100%;
  padding-top: 1.2%;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

body a {
  text-decoration: none;
}



/** styles for large screens (tablets, laptops)**/
.post-thumbnail {
  padding: 1.5%;
  width: 33.33%;
}

.post-thumbnail-title {
  padding-top: 30px;
  font-family: Times;
  font-style: italic;
  font-weight: 100;
  font-size: 24px;
  line-height: 28px;
  color: black;
  text-decoration: none;
}

.post-thumbnail-title:hover {
  text-decoration: underline;
}

.post-thumbnail-image {
  object-fit: cover;
  width: 100%;
  height: 292px;
}

.post-thumbnail-image:hover {
  opacity: 50%;
}

.post-thumbnail-blurb {
  width: 80%;
  font-family: "Times New Roman";
  font-size: 16px;
  line-height: 18px;
  font-weight: 100;
  color: black;
  padding-top: 14px;
}

.post-thumbnail-credits {
  color: #7C7C7C;
  font-size: 12px;
  padding-top: 14px;
  font-family: Arial;
}

/** styles for smol screens **/
@media screen and (max-width: 768px) {
  .post-thumbnail {
    width: 48%;
  }
}

/** styles for smol screens **/
@media screen and (max-width: 500px) {
  .post-thumbnail {
    width: 96%;
    padding-bottom: 20px;
  }
}

.prev-next-container {
  height: 100%;
  position: fixed;
  width: 21%;
  right: 0px;
  top: 120px;
}

.prev-next-container a {
  color: black;
}

.pn-content {
 margin-top: 40%;
}

.pn-text {
  font-family: Times;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  width: 150px;
  margin-left: 16%;
  padding-top: 10px;
  padding-bottom: 16px;
}

.arrow-image {
  width: 150px;
  margin-left: 16%;
}

/** mobile styles **/
@media only screen and (max-width: 600px) {
  .prev-next-container {
    position: relative;
    width: 100%;
    height: auto;
    top: 0;
    padding: 5%;
    justify-content: space-between;
  }

  .pn-content {
   margin-top: 0;
   width: 100%;
   display: flex;
  }

  .pn-text {
    font-size: 12px;
    line-height: 12px;
    padding-bottom: 16px;
    margin-left: 0;
  }

  .arrow-image {
    width: 80px;
    margin-left: 0;
  }

  .right {
    text-align: right;
  }
}

.post-title-header {
  font-family: Times;
  font-style: italic;
  font-weight: normal;
  font-size: 60px;
  line-height: 83px;
  text-align: center;
}

.back {
  font-family: Arial;
  font-style: normal;
  font-size: 20px;
  line-height: 23px;;
  color: #FC08D5;
  margin-top: 130px;
  margin-left: 130px;
  position: fixed;
}
.back:hover {
  text-decoration: underline;
}

.back a {
  color: #FC08D5;
}

.post-middle {
  width: 56%;
  padding-top: 130px;
  padding-left: 3%;
  padding-right: 3%;
  margin: 0 auto;
}

.post-main-image {
  object-fit: cover;
  width: 100%;
  height: 441px;
  padding-top: 50px;
}

.post-image {
  object-fit: cover;
  width: 100%;
  height: 441px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.post-content {
  padding-top: 47px;
  font-family: Times;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding-bottom: 200px;
}

/** mobile styles **/
@media only screen and (max-width: 600px) {
  .back {
    font-size: 12px;
    color: #FC08D5;
    margin-top: 12px;
    margin-left: 5%;
    position: absolute;
  }

  .post-middle {
    width: 100%;
    padding-top: 35px;
    padding-left: 5%;
    padding-right: 5%;
    margin: 0 auto;
  }

  .post-title-header {
    font-size: 30px;
    line-height: 30px;
  }

  .post-content {
    padding-bottom: 10px;
  }

  .post-main-image {
    padding-top: 47px;
    height: 200px;
  }
}

.image {
    margin: auto auto 15px 15px;
    display: inline-block;
    height: 400px;
}

.image-container {
    padding-top: 12%;
    display: inline-block;
    white-space: nowrap;
    overflow: visible;
}

/* global styling */
.issue-header {
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
}

.issue-theme {
  font-family: Times New Roman;
  font-style: italic;
  font-weight: normal;
}

.view-button a{
  display: block;
  font-family: Arial;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #FC08D5;
  text-decoration: none;
  background: none;
  padding-left: 6px;
  padding-top: 4px;
  padding-right: 6px;
  padding-bottom: 3px;
}

.view-button {
  margin-top: 12px;
  border: 2px solid #FC08D5;
  border-radius: 7px;
  text-align: center;
  width: 220px;
}

.view-button a:hover{
  background: #FC08D5;
  color: #FFFFFF;
}

/* for smaller screens */
@media only screen and (max-width: 700px) {
  .cover {
    width: 70%;
  }

  .view-button {
    margin-top: 12px;
    border: 2px solid #FC08D5;
    border-radius: 7px;
    text-align: center;
    width: 160px;
  }

  .issue9-container {
    padding: 25px;
    position: absolute;
    top: 89.75px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .issue-header {
    font-size: 14px;
  }

  .issue-theme {
    font-size: 38px;
    padding-bottom: 27px;
  }

  .issue9-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

/* this is for large screen (laptop) */
@media screen and (min-width: 701px) {
  .view-button {
    width: 160px;
    margin-bottom: 20px;
  }

  .cover {
    width: 330px;
    margin-top: 146px;
    margin-bottom: 5px;
  }

  .issue9-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .issue-header {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;

    position: absolute;
    left: 79px;
    top: 126px;
  }

  .issue-theme {
    font-size: 52px;
    line-height: 60px;

    position: absolute;
    left: 79px;
    top: 146px;
  }

  .issue9-container-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

